import { Guid } from '@/common/models/Guid';

import { WorkspaceProperties } from '../shared/WorkspaceProperties';
import { WorkspaceStatuses } from '../shared/WorkspaceStatuses';

export class AdminWorkspace {
  id: Guid;
  name: string;
  properties: WorkspaceProperties;
  companyId: Guid;
  status: WorkspaceStatuses;

  constructor(props?: Partial<AdminWorkspace>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.companyId = Guid.valueOrEmpty(props.companyId);
    this.properties = new WorkspaceProperties(props.properties);
  }
}
