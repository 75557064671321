import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useEvent } from '@/common/hooks/useEvent';
import { Guid } from '@/common/models/Guid';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceDeleteRequest } from '../_types';

interface Props {
  onSuccess?: (workspaceId: Guid | string) => void;
}

export const useWorkspaceDelete = ({ onSuccess }: Props) => {
  const [handler, { isHandling }] = useActionHandler();

  const callback = useEvent((request: AdminWorkspaceDeleteRequest) =>
    handler(() => AdminWorkspaceActions.deleteAsync(request), {
      onSuccess: () => onSuccess(request.workspaceId)
    })
  );

  return [callback, isHandling] as const;
};
