import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const QuizIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12.2582 8.02434C11.7927 7.94449 11.314 8.03197 10.9068 8.27129C10.4996 8.5106 10.1902 8.88631 10.0335 9.33187C9.85018 9.85286 9.27926 10.1266 8.75827 9.94336C8.23728 9.76008 7.96351 9.18917 8.14678 8.66818C8.46025 7.77707 9.07898 7.02565 9.89339 6.54702C10.7078 6.06839 11.6653 5.89343 12.5963 6.05313C13.5274 6.21283 14.3719 6.69688 14.9802 7.41955C15.5884 8.14207 15.9214 9.0565 15.9201 10.0009C15.9197 11.5313 14.7851 12.5419 13.9748 13.0821C13.5392 13.3725 13.1107 13.586 12.795 13.7263C12.6358 13.7971 12.5016 13.8508 12.405 13.8876C12.3566 13.9061 12.3174 13.9204 12.2888 13.9305L12.2541 13.9427L12.243 13.9465L12.2391 13.9478L12.2376 13.9483C12.2373 13.9484 12.2363 13.9487 11.9201 13L12.2363 13.9487C11.7124 14.1234 11.1461 13.8402 10.9714 13.3162C10.7969 12.7927 11.0796 12.2267 11.6028 12.0517L11.6188 12.0461C11.6342 12.0406 11.6594 12.0315 11.693 12.0187C11.7605 11.993 11.8607 11.9529 11.9827 11.8987C12.2296 11.789 12.551 11.6276 12.8654 11.418C13.555 10.9582 13.9201 10.4692 13.9201 10L13.9201 9.99853C13.9208 9.52621 13.7543 9.06889 13.4502 8.70755C13.146 8.34622 12.7238 8.10419 12.2582 8.02434ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18H12.01C12.5623 18 13.01 17.5523 13.01 17C13.01 16.4477 12.5623 16 12.01 16H12Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
