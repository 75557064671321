import { Guid } from '@/common/models/Guid';
import { asSingularOrPlural } from '@/common/utils/StringFunctions';

import { CompanyPriceCredit } from './CompanyPriceCredit';
import { HubCampaignWithTrackingModel } from './HubCampaignWithTrackingModel';

export class CompanyCreditUsage {
  plans: CompanyPlanCreditUsageResponse[];
  constructor(props?: Partial<CompanyCreditUsage>) {
    props = props || {};
    Object.assign(this, props);
    this.plans = (props.plans || []).map(
      (x) => new CompanyPlanCreditUsageResponse(x)
    );
  }
}

export class CompanyPlanCreditUsageResponse {
  planId: Guid;
  planName: string;
  planDescription: string;
  unusedCredits: CompanyPriceCredit[];
  hubs: CompanyHubCreditUsageResponse[];

  get hasUnusedCredits() {
    return this.unusedCredits.length ? true : false;
  }

  get creditsDisplay() {
    const count = this.unusedCredits.length;
    return `${count.toLocaleString()} flexi ${asSingularOrPlural(
      'credit',
      count
    )}`;
  }

  get hasHubs() {
    return this.hubs?.length ? true : false;
  }

  constructor(props?: Partial<CompanyPlanCreditUsageResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.planId = Guid.valueOrEmpty(props.planId);
    this.unusedCredits = (props.unusedCredits || []).map(
      (x) => new CompanyPriceCredit(x)
    );
    this.hubs = (props.hubs || []).map(
      (x) => new CompanyHubCreditUsageResponse(x)
    );
  }
}

export class CompanyHubCreditUsageResponse {
  siteId: Guid;
  siteName: string;
  siteDomain: string;
  unUsedCredits: CompanyPriceCredit[];
  activeCampaign?: HubCampaignWithTrackingModel;
  pastCampaigns: HubCampaignWithTrackingModel[];

  get unusedCreditsDisplay() {
    const count = this.unUsedCredits.length;
    return `${count.toLocaleString()} ${asSingularOrPlural('credit', count)}`;
  }

  get hasPastCampaigns() {
    return !!this.pastCampaigns?.length;
  }

  constructor(props?: Partial<CompanyHubCreditUsageResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.siteId = Guid.valueOrNew(props.siteId);
    if (props.activeCampaign)
      this.activeCampaign = new HubCampaignWithTrackingModel(
        props.activeCampaign
      );
    this.pastCampaigns = (props.pastCampaigns || []).map(
      (x) => new HubCampaignWithTrackingModel(x)
    );
    this.unUsedCredits = (props.unUsedCredits || []).map(
      (x) => new CompanyPriceCredit(x)
    );
  }
}
