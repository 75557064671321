import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TreasureHuntIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5257 3.14935C21.8205 3.33156 22 3.65342 22 4V17C22 17.3788 21.786 17.725 21.4472 17.8944L15.4472 20.8944C15.1657 21.0352 14.8343 21.0352 14.5528 20.8944L9 18.118L3.44721 20.8944C3.13723 21.0494 2.76909 21.0329 2.47427 20.8507C2.17945 20.6684 2 20.3466 2 20V7C2 6.62123 2.214 6.27497 2.55279 6.10557L8.55279 3.10557C8.83431 2.96481 9.16569 2.96481 9.44721 3.10557L15 5.88197L20.5528 3.10557C20.8628 2.95058 21.2309 2.96714 21.5257 3.14935ZM4 7.61804V18.382L8.55279 16.1056C8.83431 15.9648 9.16569 15.9648 9.44721 16.1056L15 18.882L20 16.382V5.61804L15.4472 7.89443C15.1657 8.03519 14.8343 8.03519 14.5528 7.89443L9 5.11804L4 7.61804ZM9 11C9.55228 11 10 11.4477 10 12V12.01C10 12.5623 9.55228 13.01 9 13.01C8.44772 13.01 8 12.5623 8 12.01V12C8 11.4477 8.44772 11 9 11ZM7 13C7 12.4477 6.55228 12 6 12C5.44772 12 5 12.4477 5 13V13.01C5 13.5623 5.44772 14.01 6 14.01C6.55228 14.01 7 13.5623 7 13.01V13ZM12.2929 10.2929C12.6834 9.90237 13.3166 9.90237 13.7071 10.2929L15 11.5858L16.2929 10.2929C16.6834 9.90237 17.3166 9.90237 17.7071 10.2929C18.0976 10.6834 18.0976 11.3166 17.7071 11.7071L16.4142 13L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3166 16.0976 16.6834 16.0976 16.2929 15.7071L15 14.4142L13.7071 15.7071C13.3166 16.0976 12.6834 16.0976 12.2929 15.7071C11.9024 15.3166 11.9024 14.6834 12.2929 14.2929L13.5858 13L12.2929 11.7071C11.9024 11.3166 11.9024 10.6834 12.2929 10.2929Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
