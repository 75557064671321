import { useQueryClient } from '@tanstack/react-query';
import { NextRouter } from 'next/router';
import posthog from 'posthog-js';
import { ParsedUrlQuery } from 'querystring';
import {
  createContext,
  FC,
  MutableRefObject,
  useContext,
  useEffect,
  useRef
} from 'react';
import { createStore, useStore } from 'zustand';

import { useAdminShortcuts } from '@/admin/hooks/useAdminShortcuts';
import {
  AdminRoutes,
  resolveAdminRoute
} from '@/admin/utils/Routes/AdminRoutes';
import { useReactQuerySync } from '@/common/data/react-query/useReactQuerySync';
import { Company } from '@/common/models/companies/Company';
import { CompanyOptions } from '@/common/models/companies/CompanyOptions';
import { Guid } from '@/common/models/Guid';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminAdministratorService } from '../data/AdminAdministratorService';
import { AdminAuthService } from '../data/AdminAuth/AdminAuthService';
import { AdminCache } from '../data/AdminCache';
import { AdminCompanyService } from '../data/AdminCompanyService';
import { AdminWorkspaceService } from '../data/Workspaces/AdminWorkspaceService';
import { Administrator } from '../models/Administrator';
import { PortalSpotlight } from './PortalSpotlight';
import { useIsSuperUser } from './shared/HasPermission/useHasPermission';

export interface AdminPortalProps {
  company?: Company;
  companyOptions: CompanyOptions;
  administrator: Administrator;
  workspace: AdminWorkspace;
  workspaces: Array<AdminWorkspace>;
  routerRef: MutableRefObject<NextRouter>;
  pathname: string;
  query: ParsedUrlQuery;
  isUsingAppRouter?: boolean;
  mobileNavOpened?: boolean;
  navCollapsed?: boolean;
  withSpotlight?: boolean;
  withShortcuts?: boolean;
}

export interface AdminPortalState extends AdminPortalProps {
  changeCompanyAsync: (
    companyId: Guid,
    workspaceId?: Guid,
    siteId?: Guid,
    redirectUrl?: string
  ) => Promise<unknown>;
  changeWorkspaceAsync: (
    workspaceId: Guid | string,
    updateRoute?: boolean
  ) => Promise<boolean>;
  addWorkspaceAsync: (
    workspace: AdminWorkspace,
    updateCurrentWorkspace?: boolean
  ) => Promise<boolean>;
  updateWorkspace: (workspace: AdminWorkspace) => void;
  removeWorkspaceAsync: (workspaceId: Guid | string) => Promise<boolean>;
  updateMobileNavOpened: (value: boolean) => void;
  updateNavCollapsed: (value: boolean) => void;
}

type AdminPortalStore = ReturnType<typeof createPortalStore>;

interface PartialAdminPortalProps
  extends Pick<AdminPortalProps, 'routerRef'>,
    Omit<Partial<AdminPortalProps>, 'routerRef'> {}

const createPortalStore = (initProps?: PartialAdminPortalProps) => {
  const DEFAULT_PROPS: AdminPortalProps = {
    company: undefined,
    companyOptions: undefined,
    administrator: undefined,
    workspace: undefined,
    workspaces: [],
    routerRef: undefined,
    pathname: undefined,
    query: undefined,
    isUsingAppRouter: false,
    mobileNavOpened: false,
    navCollapsed: false
  };
  const queryClient = useQueryClient();

  return createStore<AdminPortalState>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    changeWorkspaceAsync: async (
      workspaceId: Guid | string,
      updateRoute?: boolean
    ) => {
      const workspaces = get().workspaces;
      const currentWorkspace = get().workspace;
      if (Guid.areEqual(workspaceId, currentWorkspace?.id)) return true;
      let newWorkspace = workspaces.find((x) => x.id.equals(workspaceId));
      if (!newWorkspace) {
        newWorkspace = workspaces[0];
      }
      AdminCache.setWorkspace(newWorkspace.id);
      set(() => ({ workspace: newWorkspace }));
      if (updateRoute) {
        const router = get().routerRef.current;
        const splitPath = router.pathname.split('/');
        let newPath = '';
        //compose new route
        for (let i = 0; i < splitPath.length; i++) {
          if (i !== 0) newPath += '/';
          if (i === 3) {
            //currently workspace switcher can only be called on routes with /portal/workspaces/{workspaceId}
            //if this ever changes this logic will need updating
            newPath += newWorkspace.id.toString();
          } else {
            //if we are on a route with another guid assume it is scoped to the original workspace
            //so we don't want to keep it in the route and stop building the path
            if (Guid.isValid(splitPath[i])) break;
            newPath += splitPath[i];
          }
        }
        await router.push(newPath);
      }
      return true;
    },
    addWorkspaceAsync: (
      workspace: AdminWorkspace,
      updateCurrentWorkspace?: boolean
    ) => {
      const router = get().routerRef.current;
      const workspaces = get().workspaces;
      if (!!updateCurrentWorkspace) {
        set(() => ({ workspace, workspaces: [...workspaces, workspace] }));
        return router.push(`/portal/workspaces/${workspace.id}/hubs`);
      } else {
        set(() => ({ workspaces: [...workspaces, workspace] }));
        return Promise.resolve(true);
      }
    },
    updateWorkspace: (workspace: AdminWorkspace) => {
      const workspaces = get().workspaces;
      const idx = workspaces.findIndex((x) => x.id.equals(workspace.id));

      if (idx >= 0) {
        workspaces[idx] = workspace;
        set(() => ({ workspace, workspaces }));
      }
    },
    removeWorkspaceAsync: (workspaceId: Guid | string) => {
      const router = get().routerRef.current;
      const workspaces = get().workspaces;

      // can't remove the last workspace
      if (workspaces.length <= 1) {
        return Promise.resolve(false);
      }

      const idx = workspaces.findIndex((x) => x.id.equals(workspaceId));
      if (idx < 0) {
        return Promise.resolve(false);
      }
      workspaces.splice(idx, 1);
      const currentWorkspace = get().workspace;
      if (currentWorkspace.id.equals(workspaceId)) {
        //change to the first workspace if we are removing our current workspace
        const newWorkspace = workspaces[0];
        set(() => ({ workspace: newWorkspace, workspaces: workspaces }));
        return router.push(`/portal/workspaces/${newWorkspace.id}/hubs`);
      }
      set(() => ({ workspaces: workspaces }));
      return Promise.resolve(true);
    },
    changeCompanyAsync: async (
      companyId: Guid,
      workspaceId?: Guid,
      siteId?: Guid,
      redirectUrl?: string
    ) => {
      const [company, companyOptions, sessionId, workspaces] =
        await Promise.all([
          AdminCompanyService.getAsync(companyId),
          AdminCompanyService.getOptionsAsync(companyId),
          AdminAuthService.actions.updateSessionCompanyAsync(companyId),
          AdminWorkspaceService.actions.searchAsync({
            skip: 0,
            take: 200,
            companyId
          })
        ]);
      if (sessionId) {
        AdminCache.setAdministratorSession(sessionId);
      }
      posthog.group('company', company.id.toString(), {
        name: company.name
      });
      AdminCache.setCompany(company.id);
      const newWorkspace =
        workspaces.items.find((w) => w.id.equals(workspaceId)) ||
        workspaces.items.find((w) => w.id.equals(AdminCache.workspaceId)) ||
        workspaces.items[0];

      AdminCache.setWorkspace(newWorkspace.id);
      set(() => ({
        company: company,
        companyOptions: companyOptions,
        workspaces: workspaces.items,
        workspace: newWorkspace
      }));
      queryClient.setQueryData(
        AdminCompanyService.cacheKeys.company(company.id),
        company
      );
      queryClient.setQueryData(
        AdminCompanyService.cacheKeys.companyOptions(company.id),
        companyOptions
      );
      queryClient.setQueryData(
        AdminWorkspaceService.cacheKeys.searchWorkspaces({
          skip: 0,
          take: 200,
          companyId
        }),
        workspaces
      );
      const router = get().routerRef.current;
      if (redirectUrl) {
        return router.push(redirectUrl);
      } else if (siteId) {
        return router.push(
          resolveAdminRoute(AdminRoutes.site.base, {
            siteId: siteId
          })
        );
      } else {
        return router.push(
          resolveAdminRoute(AdminRoutes.workspaces.hubs, {
            workspaceId: newWorkspace.id
          })
        );
      }
    },
    updateMobileNavOpened: (value) => {
      set(() => ({ mobileNavOpened: value }));
    },
    updateNavCollapsed: (value) => {
      set(() => ({ navCollapsed: value }));
    }
  }));
};

// Provider wrapper

export const AdminPortalContext = createContext<AdminPortalStore | null>(null);

type AdminPortalProviderProps =
  React.PropsWithChildren<PartialAdminPortalProps>;

export function AdminPortalProvider({
  children,
  withSpotlight = true,
  withShortcuts = true,
  ...props
}: AdminPortalProviderProps) {
  const storeRef = useRef<AdminPortalStore>();
  if (!storeRef.current) {
    if (props?.workspace?.id) {
      AdminCache.setWorkspace(props.workspace.id);
    }
    storeRef.current = createPortalStore(props);
  }

  return (
    <AdminPortalContext.Provider value={storeRef.current}>
      {withSpotlight && <AdminPortalSpotlight />}
      {withShortcuts && <AdminPortalShortcuts />}
      <_WorkspaceSync />
      <_RouterSync pathname={props.pathname} query={props.query} />
      {children}
    </AdminPortalContext.Provider>
  );
}

/**
 * Prevent circular references by pulling out changeCompanyAsync and passing to PortalSpotlight
 */
export const AdminPortalSpotlight: FC = () => {
  const changeCompanyAsync = useAdminPortalContext((x) => x.changeCompanyAsync);
  return <PortalSpotlight changeCompanyAsync={changeCompanyAsync} />;
};

export const AdminPortalShortcuts: FC = () => {
  const isSuperUser = useIsSuperUser();
  useAdminShortcuts(isSuperUser);
  return null;
};

const _WorkspaceSync: FC = () => {
  const store = useAdminPortalStore();

  useEffect(() => {
    const unsub = store.subscribe((s) => {
      const currentWorkspaceId = s.workspace?.id;
      if (
        !!currentWorkspaceId &&
        !currentWorkspaceId.equals(AdminCache.workspaceId)
      ) {
        AdminCache.setWorkspace(currentWorkspaceId);
      }
    });

    return unsub;
  }, []);

  useReactQuerySync({
    items: [
      {
        name: 'a',
        cacheKey: AdminAdministratorService.cacheKeys.admin(
          AdminCache.sessionId
        ),
        callback: (a: Administrator) => {
          store.setState({
            administrator: a
          });
        }
      },
      {
        name: 'w',
        cacheKey: AdminWorkspaceService.cacheKeys.get(
          store.getState().workspace?.id
        ),
        callback: (w: AdminWorkspace) => {
          const workspaces = store.getState().workspaces ?? [];
          const newWorkspaces = workspaces.map((x) =>
            x.id.equals(w.id) ? w : x
          );
          store.setState({
            workspace: w,
            workspaces: newWorkspaces
          });
        }
      }
    ]
  });

  return null;
};

/*
 * Sync needed while we are using both the app router and pages router,
 * So that the store is kept in sync as the URL updates
 * This can likely be removed when we are fully transitioned to the app router
 */
const _RouterSync: FC<{ pathname: string; query: ParsedUrlQuery }> = ({
  pathname,
  query
}) => {
  const store = useAdminPortalStore();

  useEffect(() => {
    store.setState({
      pathname
    });
  }, [pathname]);

  useEffect(() => {
    store.setState({
      query
    });
  }, [query]);

  return null;
};

export const useAdminPortalStore = () => {
  const store = useContext(AdminPortalContext);
  if (!store) {
    throw new Error('Missing AdminPortalContext.Provider in the tree');
  }

  return store;
};

export function useAdminPortalContext<T>(
  selector: (state: AdminPortalState) => T
): T {
  const store = useAdminPortalStore();
  return useStore(store, selector);
}
