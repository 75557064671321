import { mapArray } from '@/common/utils/ArrayFunctions';
import {
  utcFormatDate,
  utcFormatDateTimeRangeShort
} from '@/common/utils/DateFunctions';

import { FeatureTrackingModel } from '../pricing/FeatureTrackingModel';
import { CompanyPriceCredit } from './CompanyPriceCredit';

export class CreditStatusModel {
  credit: CompanyPriceCredit;
  status: CreditStatusTypes;
  featuresCausingActive: FeatureTrackingModel[];

  get display(): string {
    switch (this.status) {
      case CreditStatusTypes.UnUsed:
        return `Will start on ${utcFormatDate(this.credit.startsAt)}`;
      case CreditStatusTypes.Ended:
        return `Ended - ${utcFormatDateTimeRangeShort(
          this.credit.startsAt,
          this.credit.endsAt
        )}`;
      case CreditStatusTypes.ActiveViaTime:
        return `Currently active - ${utcFormatDateTimeRangeShort(
          this.credit.startsAt,
          this.credit.endsAt
        )}`;
      case CreditStatusTypes.ActiveViaUsage: {
        if (!this.featuresCausingActive?.length) {
          return 'Active due to excess usage';
        }
        return `Active due to excess ${this.featuresCausingActive
          .map((x) => x.heading)
          .join(', ')
          .toLowerCase()} - ${utcFormatDateTimeRangeShort(
          this.credit.startsAt,
          this.credit.endsAt
        )}`;
      }
      case CreditStatusTypes.UnUsed:
        return `Will start on ${utcFormatDate(this.credit.startsAt)}`;
      default:
        return '';
    }
  }

  constructor(props: Partial<CreditStatusModel> = {}) {
    Object.assign(this, props);
    this.credit = new CompanyPriceCredit(props.credit);
    this.featuresCausingActive = mapArray(
      props.featuresCausingActive,
      (x) => new FeatureTrackingModel(x)
    );
  }
}

export enum CreditStatusTypes {
  UnUsed = 'UnUsed',
  Ended = 'Ended',
  ActiveViaTime = 'ActiveViaTime',
  ActiveViaUsage = 'ActiveViaUsage',
  ActiveViaAlwaysOn = 'ActiveViaAlwaysOn'
}
