import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CardStatuses } from '../CardStatuses';
import { CardSubTypes } from '../CardSubTypes';
import { CardTypes } from '../CardTypes';
import { PriceFeatures } from '../pricing/PriceFeatures';

export class PublishFeatureCheckStatusModel {
  features: PublishFeatureCheck[];
  constructor(props: Partial<PublishFeatureCheckStatusModel> = {}) {
    Object.assign(this, props);
    this.features = mapArray(props.features, (x) => new PublishFeatureCheck(x));
  }

  getNotAllowedFeatures() {
    return this.features.filter(
      (x) =>
        x.state === PublishFeatureCheckStates.NotAllowedNotIncluded ||
        x.state === PublishFeatureCheckStates.NotAllowedValueExceeded
    );
  }
}

export class PublishFeatureCheck {
  feature: PriceFeatures;
  featureName: string;
  state: PublishFeatureCheckStates;
  cards: PublishFeatureCheckCard[];
  actualValue: number;
  expectedValue: number;
  constructor(props: Partial<PublishFeatureCheck> = {}) {
    Object.assign(this, props);
    this.cards = mapArray(props.cards, (x) => new PublishFeatureCheckCard(x));
    if (!this.actualValue) this.actualValue = 0;
    if (!this.expectedValue) this.expectedValue = 0;
  }
}

export class PublishFeatureCheckCard {
  id: Guid;
  name: string;
  type: CardTypes;
  subType?: CardSubTypes;
  status: CardStatuses;
  alreadyTracked: boolean;

  constructor(props: Partial<PublishFeatureCheckCard> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.alreadyTracked = asBoolean(props.alreadyTracked);
  }
}

export enum PublishFeatureCheckStates {
  Allowed = 'Allowed',
  NotAllowedNotIncluded = 'NotAllowedNotIncluded',
  NotAllowedValueExceeded = 'NotAllowedValueExceeded',
  NotApplicable = 'NotApplicable'
}
