'use client';

import { NextRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { Administrator } from '@/admin/models/Administrator';
import { useCardDefinitionSelectOptions } from '@/common/components/Card/shared/useCardDefinitionSelectOptions';
import { CardDefinition } from '@/common/models/CardDefinition';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import { ICardIdentifier } from '@/common/models/CardIdentifier';
import { Company } from '@/common/models/companies/Company';
import { CompanyOptions } from '@/common/models/companies/CompanyOptions';
import { Guid } from '@/common/models/Guid';
import { isServer } from '@/common/utils/NextFunctions';

import { useAdminPortalContext } from './AdminPortalStore';

export const useAdministrator = (): Administrator | undefined => {
  return useAdminPortalContext((x) => x.administrator);
};

export const useCompany = (): Company | undefined => {
  return useAdminPortalContext((x) => x.company);
};

export const useCompanyId = (): Guid => {
  return useAdminPortalContext((x) => x.company.id);
};

export const useChangeCompany = (): ((cId: Guid, siteId?: Guid) => void) => {
  return useAdminPortalContext((x) => x.changeCompanyAsync);
};

export const useWorkspaceId = (): Guid | undefined => {
  const workspace = useAdminPortalContext((x) => x.workspace);
  if (isServer()) {
    return undefined;
  }
  return workspace?.id;
};

export const useAdminPortalOptions = (): CompanyOptions => {
  return useAdminPortalContext((x) => x.companyOptions);
};

export const useAdminPortalCardDefinition = (
  identifier: ICardIdentifier
): CardDefinition | undefined => {
  const cardDefinitions = useAdminPortalContext(
    (x) => x.companyOptions?.cardDefinitions
  );

  return useMemo(() => {
    const definition = cardDefinitions?.find((x) => x.isMatch(identifier));
    if (!definition) {
      console.error(
        `cannot find definition for type: ${identifier?.type} and sub-type: ${identifier?.subType}`
      );
    }

    return definition;
  }, [cardDefinitions, identifier]);
};
//
export const useGetAdminPortalCardDefinition = (): ((
  c: CardDescriptor
) => CardDefinition | undefined) => {
  const cardDefinitions = useAdminPortalContext(
    (x) => x.companyOptions?.cardDefinitions
  );

  return useCallback(
    (c: CardDescriptor) => cardDefinitions?.find((x) => x.isMatch(c)),
    [cardDefinitions]
  );
};

export const useAdminPortalCardDefinitions = (): CardDefinition[] => {
  return useAdminPortalContext((x) => x.companyOptions?.cardDefinitions);
};

export const useAdminPortalCardDefinitionSelectOptions = () => {
  const definitions = useAdminPortalCardDefinitions();
  return useCardDefinitionSelectOptions(definitions);
};

export const useAdminPortalRouter = (): NextRouter => {
  return useAdminPortalContext((x) => x.routerRef.current);
};
