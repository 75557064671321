import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useEvent } from '@/common/hooks/useEvent';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceReactivateRequest } from '../_types';

interface Props {
  onSuccess?: (workspace: AdminWorkspace) => void;
}

export const useWorkspaceReactivate = ({ onSuccess }: Props) => {
  const [handler, { isHandling }] = useActionHandler();

  const callback = useEvent((request: AdminWorkspaceReactivateRequest) =>
    handler(() => AdminWorkspaceActions.reactivateAsync(request), {
      onSuccess
    })
  );

  return [callback, isHandling] as const;
};
