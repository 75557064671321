import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { utcDateOrUndefined } from '@/common/utils/DateFunctions';

import { AdministratorLoginTypes } from './AdministratorLoginTypes';
import { AdministratorRole } from './AdministratorRole';
import { AdministratorRoles } from './AdministratorRoles';
import { AdministratorTypes } from './AdministratorTypes';

export class Administrator {
  id: Guid;
  type = AdministratorTypes.Standard;
  name: string = '';
  email: string = '';
  roles: AdministratorRole[] = [];
  createdAt: Date;
  loginTypes: AdministratorLoginTypes[] = [];

  constructor(props?: Partial<Administrator>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.roles = mapArray(props.roles, (x) => new AdministratorRole(x));
    this.createdAt = utcDateOrUndefined(props.createdAt);
  }

  getUserRole(companyId: Guid) {
    if (this.type == AdministratorTypes.SuperUser) {
      return 'Super User';
    }

    const adminCompany = this.roles.filter(function (item) {
      return item.companyId.equals(companyId);
    })[0];

    if (adminCompany.companyId.equals(companyId)) {
      switch (adminCompany.role) {
        case AdministratorRoles.Owner:
          return 'Owner';
        case AdministratorRoles.Editor:
          return 'Editor';
        case AdministratorRoles.Viewer:
          return 'Viewer';
      }
    }
    return 'Viewer';
  }

  hasPermission(
    companyId: Guid,
    type?: AdministratorTypes,
    role?: AdministratorRoles
  ) {
    if (!type && !role) {
      return true;
    }

    if (this.type === AdministratorTypes.SuperUser) {
      return true;
    }

    if (!companyId) {
      return false;
    }

    if (type && !this.hasTypeOrHigher(type)) {
      return false;
    }
    if (!role) {
      return true;
    }
    const companyRole = this.roles.find((x) =>
      Guid.equals(x.companyId, companyId)
    );
    return companyRole && this.hasRoleOrHigher(role, companyRole);
  }

  private hasTypeOrHigher(type: AdministratorTypes) {
    switch (type) {
      case AdministratorTypes.SuperUser:
        return this.type === AdministratorTypes.SuperUser;
      case AdministratorTypes.Standard:
        return true;
      default:
        return false;
    }
  }

  private hasRoleOrHigher(
    role: AdministratorRoles,
    companyRole: AdministratorRole
  ) {
    switch (role) {
      case AdministratorRoles.Owner:
        return companyRole.role === AdministratorRoles.Owner;
      case AdministratorRoles.Publisher:
        return (
          companyRole.role === AdministratorRoles.Owner ||
          companyRole.role === AdministratorRoles.Publisher
        );
      case AdministratorRoles.Editor:
        return (
          companyRole.role === AdministratorRoles.Owner ||
          companyRole.role === AdministratorRoles.Publisher ||
          companyRole.role === AdministratorRoles.Editor
        );
      case AdministratorRoles.Viewer:
        return (
          companyRole.role === AdministratorRoles.Owner ||
          companyRole.role === AdministratorRoles.Publisher ||
          companyRole.role === AdministratorRoles.Editor ||
          companyRole.role === AdministratorRoles.Viewer
        );
      default:
        return false;
    }
  }
}
