import { ReactNode } from 'react';

import { ITabDisplay } from '@/admin/components/shared/Tabs';
import { BadgeIcon } from '@/common/components/Icons/BadgeIcon';
import { ClipboardApproveIcon } from '@/common/components/Icons/ClipboardApproveIcon';
import { CookieIcon } from '@/common/components/Icons/CookieIcon';
import { DataCaptureIcon } from '@/common/components/Icons/DataCaptureIcon';
import { DoubleRefreshIcon } from '@/common/components/Icons/DoubleRefreshIcon';
import { FileTextAltIcon } from '@/common/components/Icons/FileTextAltIcon';
import { HubsIcon } from '@/common/components/Icons/HubsIcons';
import { LeaderboardIcon } from '@/common/components/Icons/LeaderboardIcon';
import { LockIcon } from '@/common/components/Icons/LockIcon';
import { PalletIcon } from '@/common/components/Icons/PalletIcon';
import { PasswordIcon } from '@/common/components/Icons/PasswordIcon';
import { RenameIcon } from '@/common/components/Icons/RenameIcon';
import { SettingsIcon } from '@/common/components/Icons/SettingsIcon';
import { WebsiteIcon } from '@/common/components/Icons/social-icons/WebsiteIcon';
import { UserSecurityIcon } from '@/common/components/Icons/UserSecurityIcon';
import { UsersIcon } from '@/common/components/Icons/UsersIcon';
import { WorkflowIcon } from '@/common/components/Icons/WorkflowIcon';

export enum SiteSettingsSections {
  Hub = 'site-hub-section',
  Styling = 'site-styling-section',
  Domain = 'site-domain-section',
  Users = 'site-users-section',
  Communications = 'site-communications-section',
  DataCapture = 'site-data-capture-section',
  Terms = 'site-terms-section',
  SEMSEO = 'site-sem-seo-section',
  GDPR = 'site-gdpr-section',
  Integrations = 'site-integrations-section',
  SuperUser = 'site-super-user-section',
  Leaderboards = 'site-leaderboards-section'
}

export enum HubInnerSections {
  HubName = 'hub-name',
  HubActiveCampaign = 'hub-active-campaign',
  RealTimeUpdates = 'hub-real-time-updates',
  HubEntryConditions = 'hub-entry-conditions',
  HubDraftPreviewPassword = 'hub-draft-preview-password'
}

export enum StylingInnerSections {
  HubFont = 'hub-font',
  HubColors = 'hub-colors'
}

export enum UsersInnerSections {
  Profiles = 'users-profiles',
  Badges = 'users-badges',
  Accounts = 'users-accounts'
}

export enum CommunicationsInnerSections {
  SMSSenderName = 'sms-sender-name'
}

export enum DataCaptureInnerSections {
  DefaultForm = 'default-form'
}

export enum SEOSEMInnerSections {
  SeoIndexing = 'search-engine-indexing',
  SearchConsole = 'search-console',
  SeoMetadata = 'seo-metadata'
}

export enum GDPRInnerSections {
  CookieConsent = 'cookie-consent',
  OneTrust = 'one-trust'
}

export class SiteSettingsPanelMeta {
  id: string;
  label: string;
  icon?: ReactNode;
  isSuperUser?: boolean;
  tags: string[];
  parentData?: Pick<SiteSettingsPanelMeta, 'id' | 'icon' | 'label'>;
  children: SiteSettingsPanelMeta[];

  constructor(props: Partial<SiteSettingsPanelMeta>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.tags) this.tags = [];
    this.children = (props.children || []).map(
      (x) => new SiteSettingsPanelMeta(x)
    );

    this.tags = [...(this.tags || [])];
  }

  setParentData(parent: SiteSettingsPanelMeta) {
    this.parentData = {
      id: parent.id,
      icon: parent.icon,
      label: parent.label
    };
  }

  toTabDisplay(): ITabDisplay & { isSuperUser: boolean } {
    return {
      name: this.id,
      label: this.label,
      icon: this.icon,
      isSuperUser: this.isSuperUser
    };
  }

  getSearchParts() {
    const parts: string[] = [this.label];
    if (!this.parentData) {
      return parts;
    }

    parts.unshift(this.parentData.label);
    return parts;
  }
}

const getSectionData = () => {
  const all: SiteSettingsPanelMeta[] = [];
  const allSections: SiteSettingsPanelMeta[] = [];
  const lookup: Record<string, SiteSettingsPanelMeta> = {};

  const addSectionToArray = (
    section: SiteSettingsPanelMeta,
    parent?: SiteSettingsPanelMeta
  ) => {
    const cloned = new SiteSettingsPanelMeta(section);
    if (parent) {
      cloned.setParentData(parent);
    }

    lookup[section.id] = cloned;
    all.push(cloned);
    if (!section.children?.length) {
      allSections.push(cloned);
    }
    section.children.forEach((child) => addSectionToArray(child, cloned));
  };

  sections.forEach((s) => addSectionToArray(s));
  return { all, allSections, lookup };
};

export const sections: SiteSettingsPanelMeta[] = [
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Hub,
    label: 'Hub',
    icon: <HubsIcon />,
    children: [
      new SiteSettingsPanelMeta({
        id: HubInnerSections.HubName,
        icon: <RenameIcon />,
        label: 'Hub name'
      }),
      new SiteSettingsPanelMeta({
        id: HubInnerSections.HubActiveCampaign,
        label: 'Hub active campaign',
        tags: ['plans', 'billing']
      }),
      new SiteSettingsPanelMeta({
        id: HubInnerSections.HubEntryConditions,
        icon: <LockIcon />,
        label: 'Entry condition screen',
        tags: ['over 18', 'gated entry']
      }),
      new SiteSettingsPanelMeta({
        id: HubInnerSections.RealTimeUpdates,
        icon: <DoubleRefreshIcon />,
        label: 'Real time content updates'
      }),
      new SiteSettingsPanelMeta({
        id: HubInnerSections.HubDraftPreviewPassword,
        icon: <PasswordIcon />,
        label: 'Hub draft password',
        tags: ['preview']
      })
    ]
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Styling,
    label: 'Styling',
    icon: <PalletIcon />,
    children: [
      new SiteSettingsPanelMeta({
        id: StylingInnerSections.HubFont,
        label: 'Hub font',
        icon: <FileTextAltIcon />,
        tags: ['fonts', 'woff', 'ttf']
      }),
      new SiteSettingsPanelMeta({
        id: StylingInnerSections.HubColors,
        icon: <PalletIcon />,
        label: 'Brand colors'
      })
    ]
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Users,
    label: 'Users',
    icon: <UsersIcon />,
    children: [
      new SiteSettingsPanelMeta({
        id: UsersInnerSections.Badges,
        icon: <BadgeIcon />,
        label: 'User badges',
        tags: ['awards', 'achievements']
      }),
      new SiteSettingsPanelMeta({
        id: UsersInnerSections.Accounts,
        icon: <UserSecurityIcon />,
        label: 'User accounts',
        tags: ['login', 'sign in', 'sign up', 'authorization']
      })
    ]
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Domain,
    label: 'Hub domain',
    icon: <WebsiteIcon />,
    tags: ['url', 'white label', 'cname', 'dns']
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.DataCapture,
    label: 'Data Capture',
    icon: <DataCaptureIcon />,
    children: [
      new SiteSettingsPanelMeta({
        id: DataCaptureInnerSections.DefaultForm,
        label: 'Default form',
        tags: ['data capture']
      })
    ]
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Terms,
    label: 'Terms & Conditions',
    icon: <ClipboardApproveIcon />
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.GDPR,
    label: 'GDPR',
    icon: <UserSecurityIcon />,
    children: [
      new SiteSettingsPanelMeta({
        icon: <CookieIcon />,
        id: GDPRInnerSections.CookieConsent,
        label: 'Cookie consent',
        tags: ['gdpr']
      })
    ]
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Integrations,
    icon: <WorkflowIcon />,
    label: 'Integrations',
    tags: ['crm', 'hubspot', 'google', 'slack', 'zapier', 'adobe', 'webhooks']
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.SuperUser,
    icon: <SettingsIcon />,
    label: 'Super user',
    tags: ['cache', 'javascript injections', 'scripts'],
    isSuperUser: true
  }),
  new SiteSettingsPanelMeta({
    id: SiteSettingsSections.Leaderboards,
    icon: <LeaderboardIcon />,
    label: 'Leaderboards',
    tags: ['leaderboards'],
    isSuperUser: true
  })
];
export const {
  allSections: allSectionsExcludingParent,
  lookup: sectionsLookup
} = getSectionData();
export const sectionMenus = sections.map((x) => x.toTabDisplay());

export interface SiteSettingsPanelProps {
  type: SiteSettingsSections;
}
