import { AdminSiteService } from '@/admin/data/AdminSiteService';
import { useReactQuerySync } from '@/common/data/react-query';
import { Guid } from '@/common/models/Guid';
import { AdminSiteOptions } from '@/common/models/site/AdminSiteOptions';
import { Site } from '@/common/models/site/Site';

interface Options {
  siteId: Guid;
  onSiteChange: (site: Site) => void;
  onSiteOptionsChange: (siteOptions: AdminSiteOptions) => void;
}

export function useAdminSiteCacheSync({
  siteId,
  onSiteChange,
  onSiteOptionsChange
}: Options) {
  useReactQuerySync({
    items: [
      {
        name: 's',
        cacheKey: AdminSiteService.cacheKeys.site(siteId),
        callback: onSiteChange
      }
    ]
  });

  useReactQuerySync({
    items: [
      {
        name: 'so',
        cacheKey: AdminSiteService.cacheKeys.siteOptions(siteId),
        callback: onSiteOptionsChange
      }
    ]
  });
}
