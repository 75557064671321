import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const MemoryChallengeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2499 6.5C20.2499 8.01878 19.0187 9.25 17.4999 9.25C15.9812 9.25 14.7499 8.01878 14.7499 6.5C14.7499 4.98122 15.9812 3.75 17.4999 3.75C19.0187 3.75 20.2499 4.98122 20.2499 6.5ZM22.2499 6.5C22.2499 9.12335 20.1233 11.25 17.4999 11.25C14.8766 11.25 12.7499 9.12335 12.7499 6.5C12.7499 3.87665 14.8766 1.75 17.4999 1.75C20.1233 1.75 22.2499 3.87665 22.2499 6.5ZM9.24994 17.5C9.24994 19.0188 8.01872 20.25 6.49994 20.25C4.98116 20.25 3.74994 19.0188 3.74994 17.5C3.74994 15.9812 4.98116 14.75 6.49994 14.75C8.01872 14.75 9.24994 15.9812 9.24994 17.5ZM11.2499 17.5C11.2499 20.1234 9.12329 22.25 6.49994 22.25C3.87659 22.25 1.74994 20.1234 1.74994 17.5C1.74994 14.8766 3.87659 12.75 6.49994 12.75C9.12329 12.75 11.2499 14.8766 11.2499 17.5ZM6.98699 2.18099C6.76497 1.77732 6.18493 1.77732 5.9629 2.18099L1.72625 9.88401C1.51204 10.2735 1.79381 10.75 2.23829 10.75H10.7116C11.1561 10.75 11.4378 10.2735 11.2236 9.88401L6.98699 2.18099ZM8.3174 8.75001L6.47495 5.40008L4.63249 8.75001H8.3174ZM16.9629 13.181C17.1849 12.7773 17.765 12.7773 17.987 13.181L22.2236 20.884C22.4378 21.2735 22.1561 21.75 21.7116 21.75H13.2383C12.7938 21.75 12.512 21.2735 12.7262 20.884L16.9629 13.181ZM17.4749 16.4001L19.3174 19.75H15.6325L17.4749 16.4001Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
