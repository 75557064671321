import { ReactNode } from 'react';

import { ContactPropertyTypes } from '@/common/models/contacts/shared/ContactPropertyTypes';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';
import { OptionModel } from '@/common/models/OptionModel';
import {
  SchemaDefinition,
  SchemaItemDataTypes,
  SchemaItemDefinition
} from '@/common/models/SchemaDefinition';

export interface ContactPropertyOptionModel extends OptionModel {
  type: ContactPropertyTypes;
  fieldName: string;
  fieldLabel?: string;
  fieldType: FieldTypes;
  options: OptionModel[];
  icon?: ReactNode;
}

export const resolveContactSchemaFromOptions = (
  options: ContactPropertyOptionModel[]
) => {
  return new SchemaDefinition({
    name: 'contact',
    description: 'Contact',
    items: (options || []).map(
      (p) =>
        new SchemaItemDefinition({
          id: p.value,
          name: p.fieldName,
          dataType: fieldToSchema(p.fieldType)
        })
    )
  });
};

const fieldToSchema = (type: FieldTypes) => {
  switch (type) {
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return SchemaItemDataTypes.Date;
    case FieldTypes.BirthYear:
    case FieldTypes.Number:
    case FieldTypes.Currency:
      return SchemaItemDataTypes.Numeric;
    default:
      return SchemaItemDataTypes.String;
  }
};
