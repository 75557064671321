import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const MemeGeneratorIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.81818 20.1818V3.81818H19.3636V20.1818H4.81818ZM3 2.45455C3 2.20351 3.20351 2 3.45455 2H20.7273C20.9783 2 21.1818 2.20351 21.1818 2.45455V21.5455C21.1818 21.7965 20.9783 22 20.7273 22H3.45455C3.20351 22 3 21.7965 3 21.5455V2.45455ZM7.00005 14.3636V9.63641H17.1818V11.5642L15.514 10.3047C15.2971 10.1409 14.9453 10.1409 14.7284 10.3047L9.35412 14.3636H7.00005ZM10.9255 14.3636L15.1212 11.1948L17.1818 12.751V14.3636H10.9255ZM9.0101 15.6364H5.98183C5.84124 15.6364 5.72727 15.5224 5.72727 15.3818V8.61819C5.72727 8.4776 5.84124 8.36364 5.98183 8.36364H18.2C18.3406 8.36364 18.4545 8.4776 18.4545 8.61819V12.6993V15.3818C18.4545 15.5224 18.3406 15.6364 18.2 15.6364H9.0101ZM7.54545 11.4546C7.54545 10.7517 8.1153 10.1818 8.81823 10.1818C9.52117 10.1818 10.091 10.7517 10.091 11.4546C10.091 12.1575 9.52117 12.7274 8.81823 12.7274C8.1153 12.7274 7.54545 12.1575 7.54545 11.4546ZM8.81823 11.2C8.67764 11.2 8.56368 11.314 8.56368 11.4546C8.56368 11.5952 8.67764 11.7092 8.81823 11.7092C8.95882 11.7092 9.07279 11.5952 9.07279 11.4546C9.07279 11.314 8.95882 11.2 8.81823 11.2ZM6.78788 6.54545C6.7042 6.54545 6.63636 6.47762 6.63636 6.39394V4.87879C6.63636 4.79511 6.7042 4.72727 6.78788 4.72727L17.3939 4.72727C17.4776 4.72727 17.5455 4.79511 17.5455 4.87879V6.39394C17.5455 6.47762 17.4776 6.54545 17.3939 6.54545L6.78788 6.54545ZM6.63636 19.1212C6.63636 19.2049 6.7042 19.2727 6.78788 19.2727H17.3939C17.4776 19.2727 17.5455 19.2049 17.5455 19.1212V17.6061C17.5455 17.5224 17.4776 17.4545 17.3939 17.4545H6.78788C6.7042 17.4545 6.63636 17.5224 6.63636 17.6061V19.1212Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
