import { AdminLiveApi } from '@/admin/data/AdminApi';
import { Guid } from '@/common/models/Guid';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';
import { QueryParams } from '@/common/utils/QueryParams';

import {
  AdminWorkspaceCreateRequest,
  AdminWorkspaceDeactivateRequest,
  AdminWorkspaceDeleteRequest,
  AdminWorkspaceGetQuery,
  AdminWorkspaceReactivateRequest,
  AdminWorkspaceSearchRequest,
  AdminWorkspaceUpdateRequest
} from './_types';

export const AdminWorkspaceActions = {
  createAsync: async (request: AdminWorkspaceCreateRequest) => {
    return new AdminWorkspace(
      await AdminLiveApi.post<AdminWorkspace>(`workspaces`, request)
    );
  },
  getAsync: async ({ workspaceId }: AdminWorkspaceGetQuery) => {
    return new AdminWorkspace(
      await AdminLiveApi.get<AdminWorkspace>(`workspaces/${workspaceId}`)
    );
  },
  updateAsync: async ({ id, ...rest }: AdminWorkspaceUpdateRequest) =>
    new AdminWorkspace(await AdminLiveApi.put(`workspaces/${id}`, rest)),

  deleteAsync: ({ workspaceId }: AdminWorkspaceDeleteRequest) =>
    AdminLiveApi.delete(`workspaces/${workspaceId}`),

  deactivateAsync: ({ workspaceId }: AdminWorkspaceDeactivateRequest) =>
    AdminLiveApi.put(`workspaces/${workspaceId}/deactivate`),

  reactivateAsync: async ({ workspaceId }: AdminWorkspaceReactivateRequest) =>
    new AdminWorkspace(
      await AdminLiveApi.put<AdminWorkspace>(
        `workspaces/${workspaceId}/reactivate`
      )
    ),

  searchAsync: ({ take, skip, ...rest }: AdminWorkspaceSearchRequest) =>
    AdminLiveApi.getPage(
      `workspaces${new QueryParams(rest).setTakeSkip(take, skip).toString()}`,
      (x) => new AdminWorkspace(x)
    ),

  createPostmarkServerAsync: async (workspaceId: Guid) =>
    new AdminWorkspace(
      await AdminLiveApi.post<AdminWorkspace>(
        `workspaces/${workspaceId}/postmark-server`
      )
    )
};
