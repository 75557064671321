import { Guid } from '@/common/models/Guid';
import { PublishingSchedule } from '@/common/models/PublishingSchedule';
import { ScheduleTime } from '@/common/models/ScheduleTime';
import { utcDateOrNow } from '@/common/utils/DateFunctions';
import { getSiteUrl } from '@/common/utils/SiteFunctions';

import { SiteProperties } from './SiteProperties';
import { SiteStatuses } from './SiteStatuses';

export class SiteSearchResponse {
  id: Guid;
  companyId: Guid;
  companyName: string;
  workspaceId: Guid;
  name: string = '';
  status: SiteStatuses;
  domain: string = '';
  createdAt: Date;
  updatedAt: Date;
  defaultFormId?: Guid;
  defaultPageId?: Guid;
  properties: SiteProperties;
  publishAt: ScheduleTime;
  unPublishAt: ScheduleTime;

  get isArchived() {
    return this.status === SiteStatuses.Archived;
  }

  get isPublished() {
    return this.status === SiteStatuses.Published;
  }

  get isDraft() {
    return this.status === SiteStatuses.Draft;
  }

  constructor(props?: Partial<SiteSearchResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.status = props.status || SiteStatuses.Draft;
    this.companyId = Guid.valueOrEmpty(props.companyId);
    this.workspaceId = Guid.valueOrEmpty(props.workspaceId);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.defaultFormId = Guid.valueOrUndefined(props.defaultFormId);
    this.defaultPageId = Guid.valueOrUndefined(props.defaultPageId);
    this.properties = new SiteProperties(props.properties);
    this.publishAt = new ScheduleTime(props.publishAt);
    this.unPublishAt = new ScheduleTime(props.unPublishAt);
  }

  getUrl(noScheme?: boolean): string {
    return getSiteUrl(this.domain, noScheme);
  }

  get hasPublishOrUnpublishDate() {
    return !!this.publishAt.utc || !!this.unPublishAt.utc;
  }

  get publishingSchedule(): PublishingSchedule {
    return new PublishingSchedule({
      publishAt: this.publishAt,
      unPublishAt: this.unPublishAt
    });
  }

  get timeZoneId(): string | undefined {
    return this.publishAt.timeZoneId ?? this.unPublishAt.timeZoneId;
  }
}
