import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const SpinToWinIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg className={tablerIconClasses(className)} ref={ref} {...rest}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M3 12h6" />
        <path d="M15 12h6" />
        <path d="M13.6 9.4l3.4 -4.8" />
        <path d="M10.4 14.6l-3.4 4.8" />
        <path d="M7 4.6l3.4 4.8" />
        <path d="M13.6 14.6l3.4 4.8" />
      </IconSvg>
    );
  }
);
