import { Guid } from '@/common/models/Guid';

export enum OnboardStartCodes {
  Unknown = 'Unknown',

  Success = 'Success',

  ExistingAdministrator = 'ExistingAdministrator'
}

export class OnboardStartResponse {
  code: OnboardStartCodes = OnboardStartCodes.Unknown;
  adminId?: Guid;

  constructor(props?: Partial<OnboardStartResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.adminId = Guid.valueOrUndefined(props.adminId);
  }
}
