import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const HtmlIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 2C2.67157 2 2 2.67157 2 3.5V20.5C2 21.3284 2.67157 22 3.5 22H20.5C21.3284 22 22 21.3284 22 20.5V3.5C22 2.67157 21.3284 2 20.5 2H3.5ZM20 8H4V20H20V8ZM4 4H20V6H4V4ZM10.7071 10.2929C11.0976 10.6834 11.0976 11.3166 10.7071 11.7071L8.41421 14L10.7071 16.2929C11.0976 16.6834 11.0976 17.3166 10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L6.29289 14.7071C5.90237 14.3166 5.90237 13.6834 6.29289 13.2929L9.29289 10.2929C9.68342 9.90237 10.3166 9.90237 10.7071 10.2929ZM13.2929 10.2929C13.6834 9.90237 14.3166 9.90237 14.7071 10.2929L17.7071 13.2929C18.0976 13.6834 18.0976 14.3166 17.7071 14.7071L14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071C12.9024 17.3166 12.9024 16.6834 13.2929 16.2929L15.5858 14L13.2929 11.7071C12.9024 11.3166 12.9024 10.6834 13.2929 10.2929Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
