import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

import { AdministratorRole } from './AdministratorRole';
import { AdministratorTypes } from './AdministratorTypes';

export enum SessionResponseCode {
  Unknown = 'Unknown',
  Success = 'Success',
  Error = 'Error',
  InvalidLoginType = 'InvalidLoginType'
}

export class AdministratorSession {
  id: Guid;
  administratorId: Guid;
  companyId: Guid;
  name: string = '';
  email: string = '';
  type: AdministratorTypes = AdministratorTypes.Standard;
  roles: AdministratorRole[] = [];

  constructor(props?: Partial<AdministratorSession>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.administratorId = Guid.valueOrEmpty(props.administratorId);
    this.companyId = Guid.valueOrEmpty(props.companyId);
    this.roles = mapArray(props.roles, (x) => new AdministratorRole(x));
  }
}

/**
 * @deprecated as sessions don't return a token anymore
 */
export class AuthToken {
  created: Date;
  expiration: Date;
  accessToken: string = '';
  user: AdministratorSession;

  constructor(props?: Partial<AuthToken>) {
    props = props || {};
    Object.assign(this, props);
    this.created = utcDateOrNow(props.created);
    this.expiration = utcDateOrNow(props.expiration);
    this.user = new AdministratorSession(props.user);
  }
}
