import { Guid } from '@/common/models/Guid';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

import { AdministratorInviteStatuses } from './AdministratorInviteStatuses';
import { AdministratorRoles } from './AdministratorRoles';
import { AdministratorTypes } from './AdministratorTypes';

export class AdministratorSearch {
  id: Guid;
  type: AdministratorTypes = AdministratorTypes.Standard;
  name: string = '';
  email: string = '';
  role: AdministratorRoles = AdministratorRoles.Viewer;
  inviteStatus?: AdministratorInviteStatuses;
  createdAt?: Date;
  updatedAt?: Date;
  invitedById?: Guid;
  invitedByName?: string;
  invitedByEmail?: string;
  isInvite?: boolean;

  constructor(props?: Partial<AdministratorSearch>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.invitedById = Guid.valueOrUndefined(props.invitedById);
    this.isInvite = asBoolean(props.isInvite);
  }
}
