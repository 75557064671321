import { addDays, differenceInDays, differenceInSeconds } from 'date-fns';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';

import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { MinMax } from '@/common/models/MinMax';
import { OptionModel } from '@/common/models/OptionModel';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { utcFormatDateTimeRange } from '@/common/utils/DateFunctions';
import { formatDurationCondensed } from '@/common/utils/DurationFunctions';
import { asSingularOrPlural } from '@/common/utils/StringFunctions';

import { CompanyPriceCredit } from '../companies/CompanyPriceCredit';

export class SitePublishInfo {
  siteId: Guid;
  publishedCards: SitePublishInfoCard[];
  availablePlans: SitePublishInfoPlan[];

  constructor(props: Partial<SitePublishInfo> = {}) {
    props = props || {};
    Object.assign(this, props);
    this.siteId = Guid.valueOrEmpty(props.siteId);
    this.publishedCards = mapArray(
      props.publishedCards,
      (x) => new SitePublishInfoCard(x)
    );
    this.availablePlans = mapArray(
      props.availablePlans,
      (x) => new SitePublishInfoPlan(x)
    );
  }

  getGroupedPublishedCards() {
    const newCards: SitePublishInfoCard[] = [];
    const existingCards: SitePublishInfoCard[] = [];

    this.publishedCards.forEach((c) => {
      if (c.publishedForActiveCampaign) {
        existingCards.push(c);
      } else {
        newCards.push(c);
      }
    });

    const hasNewCards = newCards.length ? true : false;
    const hasExistingCards = existingCards.length ? true : false;

    return {
      hasNewCards,
      newCards,
      hasExistingCards,
      existingCards,
      hasCards: hasNewCards || hasExistingCards
    };
  }
}

export class SitePublishInfoCard {
  id: Guid;
  name: string;
  type: CardTypes;
  subType?: CardSubTypes;
  publishedForActiveCampaign: boolean;

  constructor(props: Partial<SitePublishInfoCard> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
  }
}

export class SitePublishInfoPlan {
  id: Guid;
  name: string;
  description: string;
  isDemoPlan: boolean;
  activeCampaign?: SitePublishInfoPlanCampaign;
  availableCredits: CompanyPriceCredit[];

  get hasActiveCampaign() {
    return this.activeCampaign ? true : false;
  }

  get hasAvailableCredits() {
    return this.availableCredits?.length ? true : false;
  }

  constructor(props: Partial<SitePublishInfoPlan> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.availableCredits = mapArray(
      props.availableCredits,
      (x) => new CompanyPriceCredit(x)
    );
    if (props.activeCampaign) {
      this.activeCampaign = new SitePublishInfoPlanCampaign(
        props.activeCampaign
      );
    }
  }

  getAvailableCreditCountDisplay() {
    const count = this.availableCredits.length;
    return `${count.toLocaleString()} available ${asSingularOrPlural(
      'credit',
      count
    )}`;
  }

  getCreditOptions() {
    const options: OptionModel[] = [];

    const campaignRemainingDays = this.activeCampaign?.getRemainingDays() ?? 0;
    if (campaignRemainingDays) {
      const onlyOne = campaignRemainingDays === 1;
      options.push({
        label: `Keep active campaign - ${campaignRemainingDays} day${
          !onlyOne && 's'
        }`,
        value: '0'
      });
    }

    this.availableCredits.forEach((c, i) => {
      const value = i + 1;
      const onlyOne = value === 1;
      const creditLabel = `${value.toLocaleString()} credit${
        !onlyOne ? 's' : ''
      }`;
      const daysLabel = `${(
        value * CompanyPriceCredit.DefaultDaysDuration +
        campaignRemainingDays
      ).toLocaleString()} days`;
      options.push({
        label: `${creditLabel} - ${daysLabel}`,
        value: value.toString()
      });
    });
    return options;
  }
}

export class SitePublishInfoPlanCampaign {
  id: Guid;
  credits: CompanyPriceCredit[];

  get startsAt() {
    return sortBy(this.credits, (x) => x.startsAt)?.[0]?.startsAt;
  }

  get endsAt() {
    const started = this.startsAt;
    return !started ? null : addDays(started, this.daysDuration);
  }

  get dateRange(): MinMax<Date> {
    return { min: this.startsAt, max: this.endsAt };
  }

  get daysDuration() {
    return sum(this.credits.map((x) => x.daysDuration));
  }

  get secondsRemaining() {
    return differenceInSeconds(this.endsAt, new Date());
  }

  get secondsRemainingOfCurrentCredit() {
    return this.secondsRemaining % CompanyPriceCredit.DefaultSecondsDuration;
  }

  constructor(props: Partial<SitePublishInfoPlanCampaign> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.credits = mapArray(props.credits, (x) => new CompanyPriceCredit(x));
  }

  getActiveOrUnusedCredits() {
    return this.credits.filter((x) => x.isActive() || x.hasNotStarted());
  }

  getActiveCreditCountDisplay() {
    const count = this.getActiveOrUnusedCredits().length;
    return `${count.toLocaleString()} active ${asSingularOrPlural(
      'credit',
      count
    )}`;
  }

  getDurationDisplay() {
    return utcFormatDateTimeRange(this.startsAt, this.endsAt, {
      startVariant: 'Date',
      endVariant: 'Date'
    });
  }

  getRemainingDays() {
    return differenceInDays(this.endsAt, new Date());
  }

  getDaysDuration() {
    return differenceInDays(this.endsAt, this.startsAt);
  }

  getFormattedTimeRemaining() {
    return formatDurationCondensed(
      differenceInSeconds(this.endsAt, new Date())
    );
  }
}
