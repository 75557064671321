import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const PollIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3ZM5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10V21C7 21.5523 6.55228 22 6 22C5.44772 22 5 21.5523 5 21V10ZM18 11C17.4477 11 17 11.4477 17 12V21C17 21.5523 17.4477 22 18 22C18.5523 22 19 21.5523 19 21V12C19 11.4477 18.5523 11 18 11Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
