import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const IframeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.01 1C18.08 1.005 23 5.928 23 12c0 6.075-4.925 11-11 11h-.003C5.923 22.998 1 18.074 1 12 1 5.928 5.92 1.005 11.99 1m2.284 19.71A9.008 9.008 0 0 0 20.945 13H16.95a16.3 16.3 0 0 1-2.676 7.71ZM16.95 11h3.995a9.008 9.008 0 0 0-6.67-7.71A16.3 16.3 0 0 1 16.95 11ZM9.726 3.29A9.008 9.008 0 0 0 3.055 11H7.05a16.3 16.3 0 0 1 2.676-7.71ZM9.058 11A14.3 14.3 0 0 1 12 3.55 14.3 14.3 0 0 1 14.942 11H9.058Zm0 2h5.884A14.3 14.3 0 0 1 12 20.45 14.3 14.3 0 0 1 9.058 13ZM7.05 13a16.3 16.3 0 0 0 2.676 7.71A9.008 9.008 0 0 1 3.055 13H7.05Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
