import max from 'lodash/max';

import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { isNil } from '@/common/utils/TypeFunctions';

import { CompanyPlanCreditGroupAddOn } from '../companies/CompanyPlanCreditGroupAddOn';
import { PriceFeatures } from './PriceFeatures';
import { PriceFeatureVariableTypes } from './PriceFeatureTypes';

export class FeatureTrackingModel {
  addOns: CompanyPlanCreditGroupAddOn[];
  heading: string;
  feature: PriceFeatures;
  type: PriceFeatureVariableTypes;
  includedUnlimited: boolean;
  included?: number;
  includedPerCredit?: number;
  used?: number;
  idsUsed: Guid[];

  get hasUsedOrIncluded() {
    return this.includedUnlimited || this.included || this.used ? true : false;
  }

  get usageDisplay() {
    const isUnlimited = this.includedUnlimited;
    if (!isUnlimited && (this.included || 0) <= 0 && (this.used || 0) <= 0) {
      return 'None included';
    }
    return `${(this.used || 0).toLocaleString()} of ${
      isUnlimited ? '∞' : (this.included || 0).toLocaleString()
    }`;
  }

  get usagePercent() {
    if (this.includedUnlimited) return 100;
    if (isNil(this.included)) return 0;

    const used = this.used || 0;
    return (used / this.included) * 100;
  }

  constructor(props?: Partial<FeatureTrackingModel>) {
    props = props || {};
    Object.assign(this, props);
    this.addOns = (props.addOns || []).map(
      (x) => new CompanyPlanCreditGroupAddOn(x)
    );
    this.includedUnlimited = asBoolean(props.includedUnlimited);
    this.idsUsed = mapArray(props.idsUsed, (x) => Guid.valueOrNew(x));
  }

  isIdUsed(id: Guid) {
    return this.idsUsed.some((x) => x.equals(id));
  }

  hasAccessToFeature(
    feature: PriceFeatures,
    id?: Guid
  ): HasFeatureAccessResponse {
    const response: HasFeatureAccessResponse = {
      hasAccess: false,
      idUsed: false
    };

    if (this.feature !== feature) return response;
    if (this.type === PriceFeatureVariableTypes.Flag) {
      response.hasAccess = true;
      return response;
    }

    if (id) {
      response.idUsed = this.idsUsed.some((x) => x.equals(id));
      if (response.idUsed) {
        response.hasAccess = true;
        return response;
      }
    }

    if (this.includedUnlimited) {
      response.hasAccess = true;
      return response;
    }

    const included = this.included || 0;
    if (included <= 0) {
      return response;
    }
    const used = this.used || 0;
    response.hasAccess = used < this.included;
    return response;
  }

  shouldShowForUsage() {
    if (this.type !== PriceFeatureVariableTypes.Number) {
      return false;
    }

    //We always want to show engagements and entries
    if (
      [PriceFeatures.Engagements, PriceFeatures.Entries].some(
        (f) => f === this.feature
      )
    ) {
      return true;
    }

    return this.hasUsedOrIncluded;
  }

  getIncludedViaAddOns(numberOfCredits: number) {
    if (!this.included) return 0;
    if (!this.includedPerCredit) return this.included;
    return max([0, this.included - this.includedPerCredit * numberOfCredits]);
  }
}

export interface HasFeatureAccessResponse {
  hasAccess?: boolean;
  idUsed?: boolean;
}
