import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CardDefinition } from '../CardDefinition';
import { ICardIdentifier } from '../CardIdentifier';
import { CardTiers } from '../CardTiers';
import { PriceFeautureDefinition } from '../pricing/PriceFeautureDefinition';

export class CompanyOptions {
  _plansFeature: string = 'PlansFeature';
  appFeatures: string[];
  priceFeatures: PriceFeautureDefinition[];
  cardDefinitions: CardDefinition[];
  isTemplatesCompany: boolean;
  isOnboardingCompany: boolean;
  isKomoCompany: boolean;

  get hasPlansFeature() {
    return this.hasAppFeature(this._plansFeature);
  }
  constructor(props: Partial<CompanyOptions> = {}) {
    Object.assign(this, props);
    if (!this.appFeatures) this.appFeatures = [];
    this.priceFeatures = mapArray(
      props.priceFeatures,
      (x) => new PriceFeautureDefinition(x)
    );
    this.cardDefinitions = mapArray(
      props.cardDefinitions,
      (x) => new CardDefinition(x)
    );
    this.isTemplatesCompany = asBoolean(props.isTemplatesCompany);
    this.isOnboardingCompany = asBoolean(props.isOnboardingCompany);
    this.isKomoCompany = asBoolean(props.isKomoCompany);
  }

  hasAppFeature(name: string) {
    if (!name) return false;
    return this.appFeatures.some((x) => x.toLowerCase() === name.toLowerCase());
  }

  findDefinition(identifier?: ICardIdentifier) {
    if (!identifier?.type) return null;
    return this.cardDefinitions.find((x) => x.isMatch(identifier));
  }

  resolveTier(identifier?: ICardIdentifier) {
    const definition = this.findDefinition(identifier);
    return definition?.tier ?? CardTiers.Standard;
  }
}
