import { ImageDataModel } from '@/common/models/ImageDataModel';
import { classFromJsonOrFallback } from '@/common/utils/TypeFunctions';

export class WorkspaceProperties {
  LogoImageDataModelJson?: string;
  PostmarkKeysJson?: string;
  ReportingTimeZoneId?: string;

  constructor(props?: Partial<WorkspaceProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  get PostmarkKeys() {
    return classFromJsonOrFallback(
      PostmarkApiKeys,
      this.PostmarkKeysJson,
      new PostmarkApiKeys()
    );
  }

  set PostmarkKeys(keys) {
    this.PostmarkKeysJson = keys?.toString();
  }

  get Logo(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.LogoImageDataModelJson);
  }

  set Logo(logo) {
    this.LogoImageDataModelJson = logo?.toString();
  }
}

class PostmarkApiKeys {
  apiKey?: string;
  name?: string;
  constructor(props?: Partial<WorkspaceProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
