import {
  addDays,
  differenceInDays,
  isAfter,
  isBefore,
  isEqual,
  min
} from 'date-fns';

import { Guid } from '@/common/models/Guid';
import {
  utcDateOrUndefined,
  utcFormatDateTimeRange,
  utcFormatDateTimeRangeShort
} from '@/common/utils/DateFunctions';

import { CompanyPriceCreditTypes } from './CompanyPriceCreditTypes';

export class CompanyPriceCredit {
  public static DefaultDaysDuration = 30;
  public static DefaultSecondsDuration =
    this.DefaultDaysDuration * 24 * 60 * 60;
  id: Guid;
  startsAt?: Date;
  terminatedAt?: Date;
  expiresAt: Date;
  daysDuration: number;
  type: CompanyPriceCreditTypes;
  isNew?: boolean;

  get isExpired() {
    const now = new Date();
    return isAfter(now, this.expiresAt);
  }

  get endsAt() {
    if (!this.startsAt) return this.terminatedAt;
    const end = addDays(this.startsAt, this.daysDuration);
    return this.terminatedAt ? min([this.terminatedAt, end]) : end;
  }

  get dateRangeDisplay() {
    if (!this.startsAt) return '-';

    const fullEnd = addDays(this.startsAt, this.daysDuration);
    const dateRange = utcFormatDateTimeRangeShort(this.startsAt, fullEnd);

    if (isEqual(fullEnd, this.endsAt)) {
      return dateRange;
    }

    return `${dateRange} - Ended early ${utcFormatDateTimeRange(this.endsAt)}`;
  }

  get daysUsedPercent() {
    return (this.daysUsed / this.totalDays) * 100;
  }

  get daysUsed() {
    if (this.hasNotStarted()) return 0;

    return differenceInDays(min([new Date(), this.endsAt]), this.startsAt);
  }

  get totalDays() {
    if (!this.startsAt) return 0;
    return differenceInDays(this.endsAt, this.startsAt);
  }

  constructor(props?: Partial<CompanyPriceCredit>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.startsAt = utcDateOrUndefined(props.startsAt);
    this.expiresAt = utcDateOrUndefined(props.expiresAt);
    this.terminatedAt = utcDateOrUndefined(props.terminatedAt);
    if (!this.isNew) this.isNew = false;
  }

  hasNotStarted() {
    if (!this.startsAt) return true;
    const now = new Date();
    return isAfter(this.startsAt, now);
  }

  isActive() {
    if (!this.startsAt) return false;
    const now = new Date();
    return (
      isAfter(now, this.startsAt) &&
      isBefore(now, addDays(this.startsAt, this.daysDuration))
    );
  }

  hasEnded() {
    if (!this.startsAt) return false;
    isAfter(new Date(), this.endsAt);
  }
}
