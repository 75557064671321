import { QueryClient } from '@tanstack/react-query';
import saveAs from 'file-saver';

import { AdminCache } from '@/admin/data/AdminCache';
import {
  AllowedUserRules as CompanyAllowedUserRules,
  Company
} from '@/common/models/companies/Company';
import { CompanyCreditUsage } from '@/common/models/companies/CompanyCreditUsage';
import { CompanyOptions } from '@/common/models/companies/CompanyOptions';
import { CompanyPlan } from '@/common/models/companies/CompanyPlan';
import { CompanyPlanUpdateApiRequest } from '@/common/models/companies/CompanyPlanUpdateApiRequest';
import { CompanyPricePlanOptions } from '@/common/models/companies/CompanyPricePlanOptions';
import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { getDateForFileName } from '@/common/utils/DateFunctions';
import { QueryParams } from '@/common/utils/QueryParams';

import { AdminLiveApi } from './AdminApi';

export interface CompanySearchQuery {
  name?: string;
  take?: number;
  skip?: number;
}

const baseCacheKey = 'Company';

export class AdminCompanyService {
  public static baseCacheKey = baseCacheKey;

  public static invalidateAllQueriesAsync(queryClient: QueryClient) {
    return queryClient.invalidateQueries([baseCacheKey]);
  }

  public static cacheKeys = {
    company: (companyId?: Guid) => [baseCacheKey, companyId?.toString()],
    companyPlans: (companyId: Guid) => [
      baseCacheKey,
      'CompanyPricePlans',
      companyId
    ],
    companyOptions: (companyId?: Guid) => [
      baseCacheKey,
      'CompanyOptions',
      companyId?.toString()
    ],
    creditUsage: (companyId: Guid) => [
      baseCacheKey,
      'CompanyCreditUsage',
      companyId
    ],
    pricePlanOptions: (companyId: Guid) => [
      baseCacheKey,
      'CompanyPricePlanOptions',
      companyId.toString()
    ],
    search: (take: number, skip: number, name?: string) => [
      baseCacheKey,
      'Companies',
      AdminCache.administratorId?.toString(),
      take,
      skip,
      name
    ]
  };
  public static getAsync = async (companyId: Guid) =>
    new Company(await AdminLiveApi.get(`companies/${companyId}`));

  public static getOptionsAsync = async (companyId: Guid) =>
    new CompanyOptions(
      await AdminLiveApi.get(`companies/${companyId}/options`)
    );

  public static createAsync = async (request: {
    name: string;
    hubspotUrl?: string;
    workspaceReportingTimeZoneId: string;
  }) =>
    new Company(
      await AdminLiveApi.post<Company>(`companies`, {
        ...request
      })
    );

  public static updateAsync = async (request: {
    companyId: Guid | string;
    name: string;
    hubspotUrl?: string;
    plansEnabled?: boolean;
    createWorkspaceEnabled?: boolean;
    workspaceLimit?: number;
    createPageEnabled?: boolean;
  }) => {
    const { companyId, ...body } = request;
    return new Company(
      await AdminLiveApi.put<Company>(`companies/${companyId}`, body)
    );
  };

  public static getCreditUsageAsync = async (companyId: Guid) =>
    new CompanyCreditUsage(
      await AdminLiveApi.get(`companies/${companyId}/credits/usage`)
    );

  public static searchAsync = ({
    take = 500,
    skip = 0,
    ...rest
  }: CompanySearchQuery) =>
    AdminLiveApi.getPage<Company>(
      `companies${new QueryParams(rest).setTakeSkip(take, skip).toString()}`,
      (x) => new Company(x)
    );

  public static searchPlansAsync = async (companyId: Guid) => {
    return mapArray(
      (await AdminLiveApi.get(`companies/${companyId}/pricing-plans`)) as any,
      (x) => new CompanyPlan(x)
    );
  };

  public static getPricePlanOptionsAsync = async (companyId: Guid) => {
    return new CompanyPricePlanOptions(
      await AdminLiveApi.get(`companies/${companyId}/pricing-plans/options`)
    );
  };

  public static addPricePlanAsync = async (
    companyId: Guid,
    pricePlanId: Guid
  ) =>
    new CompanyPlan(
      await AdminLiveApi.post(`companies/${companyId}/pricing-plans`, {
        pricePlanId
      })
    );

  public static updatePricePlanAsync = async (
    companyId: Guid,
    companyPricePlanId: Guid,
    request: CompanyPlanUpdateApiRequest
  ) =>
    new CompanyPlan(
      await AdminLiveApi.put(
        `companies/${companyId}/pricing-plans/${companyPricePlanId}`,
        request
      )
    );

  public static deletePricePlanAsync = (
    companyId: Guid,
    companyPricePlanId: Guid
  ) =>
    AdminLiveApi.delete(
      `companies/${companyId}/pricing-plans/${companyPricePlanId}`
    );

  public static exportHubsAsync = (): Promise<any> =>
    AdminLiveApi.get(`hubs/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-hubs.csv`)
    });

  public static exportMembersAsync = (): Promise<any> =>
    AdminLiveApi.get(`members/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-members.csv`)
    });

  public static exportCompaniesAsync = (): Promise<any> =>
    AdminLiveApi.get(`companies/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-companies.csv`)
    });

  public static updateMemberRulesAsync = async (
    companyId: Guid,
    request: CompanyAllowedUserRules
  ): Promise<Company> =>
    new Company(
      await AdminLiveApi.put(`companies/${companyId}/member-rules`, {
        rules: request
      })
    );
}
