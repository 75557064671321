import { useMemo } from 'react';

import { CardIcon } from '@/common/components/Card/CardIcon';
import { FormSelectProps } from '@/common/components/Form/FormSelect';
import { FormMultiSelectProps } from '@/common/components/Form/FormSelect/Multi';
import { useConstant } from '@/common/hooks/useConstant';
import {
  CardDefinition,
  CardDefinitionOption
} from '@/common/models/CardDefinition';

export interface CardDefinitionSelectProps {
  filterOption: (
    candidate: { data: CardDefinitionOption },
    input: string
  ) => boolean;
}

export const useCardDefinitionSelectOptions = (
  definitions: CardDefinition[]
) => {
  const options = useMemo<CardDefinitionOption[]>(
    () => CardDefinitionOption.arrayFromDefinitions(definitions),
    [definitions]
  );

  const selectV2Props = useConstant(() => {
    const itemComponent: FormSelectProps<CardDefinitionOption>['itemComponent'] =
      {
        icon: (x) => (
          <CardIcon type={x.definition?.type} subType={x.definition?.subType} />
        )
      };

    const valueComponent: FormMultiSelectProps<CardDefinitionOption>['valueComponent'] =
      {
        icon: (x) => (
          <CardIcon
            size={'0.8rem'}
            type={x.definition?.type}
            subType={x.definition?.subType}
          />
        )
      };

    const valueIcon: FormSelectProps<CardDefinitionOption>['valueIcon'] = (
      v
    ) => <CardIcon type={v.definition?.type} subType={v.definition?.subType} />;

    return {
      valueIcon,
      itemComponent,
      valueComponent
    };
  });
  return { options, selectV2Props } as const;
};
